import { toast } from "react-toastify";
import { API_URL } from "./URL";
import axios from "axios";
import { storage } from "./Storage";
import { oauthAccessToken, oauthAccessToken1 } from "./Mockdata"

export const GetRequest = async (url, data) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: 'GET',
      params: data,
      headers: {
        Authorization: storage.fetch.auth()
      }
    })
    return res
  } catch (err) {
    if (err.status === 500 || err.status == 503) {
      toast.error(err.response.data.error || err.response.data.message)
    }
    if (err.response.data.code === 403) {
      toast.error(err.response.data.message)
      storage.destory.auth()
      setTimeout(() => {
        window.location.replace("/")

      }, 2000);
    }
  }
}


export const DirectGetRequest = async (data) => {
  const apiUrl = `https://www.freelancer.com/api/projects/0.1/projects/${data.project_id}`;

  const queryParams = {
    attachment_details: true,
    upgrade_details: true,
    file_details: true,
    job_details: true,
    hireme_details: true,
    full_description: true,
    user_details: true,
    location_details: true,
    project_collaboration_details: true,
    review_availability_details: true,
    user_status: true,
    employer_reputation: true,
    invited_freelancer_details: true,
    user_preferred_details: true,
    upgrade_details: true,
    user_membership_details: true,
    user_country_details: true,
  };

  const axiosConfig = {
    params: queryParams,
    headers: {
      'freelancer-oauth-v1': data.account_used ? oauthAccessToken : oauthAccessToken1,
    },
  };

  const axiosConfig2 = {
    headers: {
      'freelancer-oauth-v1': data.account_used === "OOCPL" ? oauthAccessToken : oauthAccessToken1,
    },
    params: {
      projects: [data.project_id],
      bidders: [73572272 , 4356844],
    },
  };

  try {
    // Fetch project details
    const projectResponse = await axios.get(apiUrl, axiosConfig);
    const projectData = projectResponse.data.result;

    // Fetch user reputation details
    const userId = projectData.owner_id;
    const apiUrl1 = `https://www.freelancer.com/api/users/0.1/users?employer_reputation=true&users%5B%5D=${userId}`;
    const userResponse = await axios.get(apiUrl1);
    const user = userResponse.data.result.users[userId];
    const rating = user?.employer_reputation?.entire_history?.overall;

    // Add rating to project data
    Object.assign(projectData, { rating });

    // Fetch bids for the project
    const apiUrl2 = `https://www.freelancer.com/api/projects/0.1/bids/`;
    const bidsResponse = await axios.get(apiUrl2, axiosConfig2);
    const bids = bidsResponse.data.result.bids;

    // Check if there are bids and assign data
    if (bids && bids.length > 0) {
      Object.assign(projectData, { bidded: true, bid_id: bids[0].id, amount: bids[0].amount, bid_desc: bids[0].description, period: bids[0].period, sealed:bids[0].sealed });
    }

    return {status:bidsResponse.status,data:projectData, message: bidsResponse.message};
  } catch (error) {
    console.error('Error in DirectGetRequest:', error);
    return error;
  }
};


export const PostRequest = async (url, data) => {

  try {
    const res = await axios.post(API_URL + url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: storage.fetch.auth()
      },
    });
    toast.success(res.data.message)
    // console.log("...",res.data);
    
    return res.data; // returning the data part of the response
  } catch (err) {
    // console.log(err);
    if(err.status == 500){
      toast.error("Internal Error")
    }
    toast.error(err.response.data.message)
    return err.response.data

  }
}
export const Api = {
  PostRequest,
  GetRequest,
  DirectGetRequest
};
