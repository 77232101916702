import { Box, Chip, Divider, Grid, List, ListItem, ListItemText, Rating, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ModalBox from './Modal';
import axios from 'axios';
import { API_URL, Img_URL } from '../Config/URL';
import { useDispatch, useSelector } from 'react-redux';
import { CSS } from './CSS';
import DoneIcon from '@mui/icons-material/Done';
import { formatDistanceToNow } from 'date-fns';
import LoaderComponent from './Loader/LoaderComponent';
import { useNavigate } from 'react-router-dom';
const Card = ({ list, results }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    // Function to handle date formatting
    let handledate = (time) => {
        const pastDate = new Date(time * 1000);
        const distance = formatDistanceToNow(pastDate, { addSuffix: true });
        const formattedDistance = distance.replace(/^(in\sabout\s|about\s)/, '');
        return "Posted " + formattedDistance
    }

    const handleClick = (id) => {
        window.open(`/project/${id}`, "_blank");

    }

    return (
        <List sx={CSS.Card.list}>
            <LoaderComponent open={loading} />

            <Box sx={CSS.Card.box}>
                {/* {/ Pop up Modal /} */}
                {/* <ModalBox data={data} open={open} setopen={setopen} loading={loading} setLoading={setLoading} /> */}
            </Box>

            {/* {/ Iterate array of projects /} */}
            {list?.map((item, index) => {
                // Format the date here
                let formattedDate = handledate(item?.submitdate);

                return (
                    <ListItem key={index} sx={CSS.Card.item} onClick={() => handleClick(item.id)}>

                        {/* Conditional rendering for the 'Bidded' badge */}
                        {item.bidded && (
                            <Chip
                                icon={<DoneIcon sx={{ color: 'white', }} />} // This is where you add the icon
                                key="bidded"
                                sx={{
                                    bgcolor: '#4caf50', color: 'white', pl: "14px", "&.css-390np-MuiChip-root": {
                                        color: "white"
                                    }
                                }} // Green background with white text
                            />
                        )}

                        <Box sx={CSS.Card.box1}>
                            <Typography sx={CSS.Card.text}>{item?.title}<span style={{color:"grey", fontSize:"14px"}}> {"(" + item?.status + ")"}</span></Typography>

                            {/* Existing upgrade badges */}
                            {item.upgrades.sealed && <Chip label={"Sealed"} key="sealed" sx={{ bgcolor: '#589af0', color: "white", mr: 1 }} />}
                            {item.upgrades.NDA && <Chip label={"NDA"} key="NDA" sx={{ bgcolor: '#115cc0', color: "white", mr: 1 }} />}
                            {item.upgrades.recruiter && <Chip label={"Recruiter"} key="recruiter" sx={{ bgcolor: '#7f21f7', color: "white", mr: 1 }} />}
                            {item.upgrades.ip_contract && <Chip label={"IP Contract"} key="ip_contract" sx={{ bgcolor: '#860146', color: "white", mr: 1 }} />}

                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} columnGap={4} sx={{ display: 'flex', justifyContent: { xs: "flex-start", md: "flex-end" } }}>
                                <Grid item>
                                    <Typography sx={CSS.Modal.head}>Bids</Typography>
                                    <Typography sx={CSS.Modal.value}>{item?.bid_stats.bid_count}</Typography>
                                </Grid>
                                <Grid>
                                    <Typography sx={CSS.Modal.head}>Average Bid</Typography>
                                    <Typography sx={CSS.Modal.value}>
                                        {item?.currency?.sign}{Math.round(item?.bid_stats?.bid_avg)} {item?.currency?.code}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ display: "flex" }}>
                            <img src={Img_URL + results[item?.owner_id]?.location?.country?.flag_url} height={"20px"} />
                            <Typography sx={{ ml: 1 }}>
                                {results[item?.owner_id]?.location?.country?.name}
                            </Typography>
                        </Box>

                        <Box sx={CSS.Card.box1}>
                            <Typography component="span">
                                <b>Budget : </b>{item?.currency?.sign}{item?.budget?.minimum} - {item?.currency?.sign}{item?.budget?.maximum} {item?.currency?.code} {item?.type}
                            </Typography>
                        </Box>

                        <Typography sx={{ fontSize: '12px', pt: 2 }}>{item.description}</Typography>

                        <Stack direction="row" spacing={1} rowGap={2} sx={{ pt: 2, display: 'flex', flexWrap: "wrap" }}>
                            {/* Iterate array of jobs for the project */}
                            {item.jobs.map((i, ind) =>
                                <Chip label={i.name} key={ind} />
                            )}
                        </Stack>

                        <Box sx={CSS.Card.box1}>
                            <Box sx={{ display: 'flex' }}>
                                <Rating name="read-only" precision={0.1} value={results[item?.owner_id]?.employer_reputation?.entire_history?.overall} readOnly />
                                <Typography sx={CSS.Card.text1}>{results[item?.owner_id]?.employer_reputation?.entire_history?.overall.toFixed(1)}</Typography>
                            </Box>

                            <Typography sx={CSS.Card.text1}>{formattedDate}</Typography>
                        </Box>

                    </ListItem>

                );
            })}

        </List>
    );
}

export default Card;
